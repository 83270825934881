.tab-control-sales{
    display: flex;
    width: fit-content;
    margin-top: 20px;
}

.tab-control-sales div{
    margin-right: 30px;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
}

.tab-control-sales div p{
    margin: 0;
    padding: 0;
}

.active-tab-sales{
    background-color:  #EE7F0D;
    width: 100%;
    height: 2px;
    border-radius: 2px;
}

.sales-record-wrap{
    margin-top: 30px;
}

.sales-graph-wrap{
    height: 400px;
    background-color: white;
    border-radius: 5px;
}

.sales-graph-wrap .chart-canvas{
    height: 100% !important;
    width: 100% !important;
    
}

.sales-graph-header{
    font-weight: bold;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
color: #334D6E;
}
