.publicNotice {
  width: 80% !important;
  padding: 45px;
}
.nav {
  width: 100%;
  display: flex;
}
.nav-back {
  justify-content: flex-start;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #000000;
}
.nav-back img {
  margin-right: 10px;
}

.p-n-cont {
  padding: 10px;
  margin-top: 40px;
}
.p-n-title {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-bottom: 43px;
}
hr {
  border: 1px solid #d9dbe9;
  margin-bottom: 34px;
}
.p-n-det {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #6e7191;
}
.p-n-top {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #14142b;
  margin-bottom: 12px;
}
.p-n-bot {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #6e7191;
}
.status-div {
  display: flex;
  align-items: center;
}
.s-d-1 {
  display: flex;
  flex-direction: column;
  margin-right: 77px;
  text-align: left;
}
.sd-1-up {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #14142b;
  margin-bottom: 14.6px;
}
.sd-1-down {
  background: #e8fcf1;
  border-radius: 2.51852px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17.6296px;
  line-height: 21px;
  color: #17bf63;
  order: 0;
  flex-grow: 0;
  padding: 0px 12.5926px;
}
.sd-1-down-p {
  background: #ffecd8;
  border-radius: 2.51852px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17.6296px;
  line-height: 21px;
  color: rgb(241 79 17);
  order: 0;
  flex-grow: 0;
  padding: 0px 12.5926px;
}

.s-d-2 {
  background: #289d65;
  box-shadow: 0px 12.1443px 18.2165px rgba(238, 127, 13, 0.16),
    0px 4.55413px 9.10825px rgba(238, 127, 13, 0.16);
  border-radius: 75.1431px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 30px;
  letter-spacing: 0.113853px;
  color: #ffffff;
}

.det-head {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 7px;
}
.det-cont {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4e4b66;
}
.det-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}
.detail-cont {
  width: 50%;
}
