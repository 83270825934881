.rhh {
  padding: 10px;
  flex: 1;
  border-radius: 10px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 100%;
}

select {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.wallet-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.wallet-modal h2 {
  font-size: 30px;
}
.wallet-modal input {
  margin-top: 10px;
  padding: 15px;
  border-radius: 5px;
  width: 100% !important;
  border: 1px solid var(--mainColor);
}

.wallet-modal input:focus {
  background-color: var(--mainColorFade);
  outline: none;
}

.btn-box {
  margin-top: 20px;
  width: 100%;
}

.btn-box button {
  padding: 12px;
  border: none;
  background: var(--mainColor);
  border-radius: 5px;
}

.btn-box button > h2 {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

.rhh-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tti {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0;
  color: #000000 !important;
}

.price {
  font-size: 30px !important;
  margin: 0;
  color: var(--appGrey3);
  text-align: center !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.price-title {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 3px;
  color: var(--mainColor);
}

.dashboard-right-sidebar button {
  border: none;
  padding: 10px;
  border-radius: 5px;
  background: var(--mainColor);
}

.dashboard-right-sidebar-btn h2 {
  margin: 0;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  font-weight: 400;
}

.history-container {
  width: 90% !important;
  border-radius: 5px;
}

.history-container-title {
  padding: 8px;
}

.history-container-title h2 {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 0;
}

.dashboard-overview {
  width: 70% !important;
  height: 100% !important;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.chartjs-render-monitor {
  width: 100% !important;
  height: 100% !important;
  /* color: #363f7925 */
}

.chart-canvas {
  height: 100%;
}

.dashboard-right-sidebar .chart-canvas {
  width: 100% !important;
}

.dashboard-right-sidebar .chart-canvas canvas {
  width: 90% !important;
  height: 189px !important;
}

.dashboard-right-sidebar .chart-canvas .reachedby-labels {
  width: 10% !important;
}
.history-container-body {
  margin-top: -20px;
  overflow: hidden imp !important;
  height: 95px;
}
.history-container-body--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  /* background: var(--bgColor); */
}
.withdraw-container {
  background: var(--appLightPink);
  width: 30px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.dashboard-overview .chart-canvas {
  width: 100% !important;
  height: 100% !important;
}

.dashboard-overview .chart-canvas canvas {
  width: 100% !important;
  height: 300px !important;
}

.dashboard-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.dashboard-right-sidebar {
  width: 27%;
  height: 319px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.reachedby-chart-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
}

.reached-by-solid-color {
  width: 13px;
  height: 13px;
  border-radius: 3px;
  margin-right: 5px;
}

.reachedby-labels div {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.dashboard-right-sidebar {
  display: flex;
  align-items: center;
}

.new-edition-wrap {
  width: 100%;
}

.new-edition-wrap button {
  width: 120px;
  height: 50px;
  background: #fef2e6;
  border-radius: 40px;
  color: #f14a06;
  outline: none;
  border: none;
  margin-top: 20px;
  margin-bottom: 8px;
  cursor: pointer;
}

.pick-stat-dateDasboard input {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  width: 242px;
  outline: none;
  padding: 5px;
  color: #f14a06;
}

.hide-scrollbar ::-webkit-scrollbar {
  display: none;
}

.mod {
  /* padding-top: 10vw; */
  /* align-items: center; */
  /* position: absolute;
    top: 0;
    left: 0; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85vh;
  background-color: #ffffff;
  z-index: 1000;
  overflow-y: scroll;
}
.pub-title {
  font-size: 15px;
  color: #666666;
  font-weight: bold;
}
.cross {
  cursor: pointer;
}
.cross h3:hover {
  color: red !important;
  transform: scale(1.2);
}
.mod-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bgColorLight2);
  padding: 35px;
  height: 55vh;
  border-radius: 10px;
  width: 40%;
}
.mod-content p {
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 0;
}

.mod-content h3 {
  text-align: center;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 0;
}
.mod-content select {
  width: 100%;
  border: none;
  padding: 8px;
  border-radius: 8px;
}

.onboard-container {
  display: flex;
  height: 75vh;
  justify-content: center;
  align-items: center;
  background-color: var(--bgColorLight);
}

.onboard {
  width: 60%;
}

.onboard h2 {
  font-size: 20px;
  text-align: center;
}

.onboard-grid {
}
.onboard-grid p {
  font-size: 12px;
}

.grid {
  border: 1px solid var(--mainColor);
}

.modal-body-rs {
  /* border: 1px solid red; */
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-body-rs h3 {
  font-weight: bold;
  margin: 0;
  font-size: 1.2vw;
}

.modal-body-rs h4 {
  margin: 0;
  font-size: 1vw;
  line-height: 20px;
}

.btn-onbording {
  border: none;
  background: var(--btnColor) !important;
  padding: 2px 0;
  width: 10vw;
}
