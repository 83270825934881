.settings {
    /* display: flex; */
    height: 70vh !important;
    
}

.settings-block {
    background: linear-gradient(to bottom, var(--bgColor),  #fff);
    height: 100%;
}

.settings h2 {
    color: var(--solidGrey);
    font-size: clamp(12px, 1.1vw, 3vw);
}

.login--form_input {
    flex: 1;
}

.ffff {
    width: 40%;
}
.react-responsive-modal-container {
    backdrop-filter: none !important;
}

.login--form_input input {
    width: 100%;
    padding: 10px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    text-transform: capitalize;
    color: var(--appText);
    letter-spacing: 2px;
    line-height: 20px;
    border-radius: 8px;
    border: none;
    background: var(--appInput);
        
}

.login--form_input2 {
    margin-top: -4px;
    background: var(--appGrey2);
    /* border-radius: 10px; */
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(12px, 1vw, 3vw);
    color: var(--solidGrey);
    /* padding: 25px; */
    border: 1px solid var(--mainColor);
    width: 100%;
        
}

.login--form_input::placeholder {
    font-size: 1vw;
    font-family: 'Montserrat', sans-serif;
}

.settings-block__btn {
    background-color: var(--mainColor);
    width: 10vw;
    border: none;
    padding: 7px 20px;
    border-radius: 30px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
}

.settings-block__btn h2:hover {
    color: var(--appGrey2);
}

Table tr {
    border-bottom: 1px solid var(--bgColor);
    font-size: 12px;
}

Table td {
    font-size: 12px;
}

.roles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
}

.roles-item1 {
    flex: 1;
}
.roles-item2 {
    flex: 1;
    display: flex;
    justify-content: center;
}
.roles-item3 {
    flex: 1;
}

.role--title {
    /* margin-top: -24px; */
    /* flex-grow: .5; */
}

.role--title h2 {
    letter-spacing: 1px;
    font-family: 'Poppins' sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px;
    margin: 0;
}

.currency-btn:hover {
    transform: scale(1.1);
    cursor: pointer;
    border-bottom: 1px solid var(--mainColor);
}

.role--btn {
    /* border: 1px solid red; */
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.role--btn__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .6vw 0;
    border-radius: 10px;
    cursor: pointer;
    width: 10%;
}

.role--btn__item1 {
    background-color: var(--appRole);
}
.role--btn__item1 h2 {
    font-size: 13px !important;
    color: var(--appRoleText);
}
.role--btn__item2 {
    background-color: var(--mainColor);
}
.role--btn__item2 h2 {
    font-size: 1vw;
    color: #fff;
}

.input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.input-row h2{
   font-size: 15px;
   letter-spacing: 1px;
}

.preff {
    background: var(--bgColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.preff-item1 {
    flex: 1;
}

.preff-item1 h2 {
    font-size: 14px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.preff-item2 {
    flex: 2;
}

.profile-container h2 {
    letter-spacing: 1px;
    font-size: 14px;
}

.preff-item2 Input {
    border: none;
    padding: 15px 10px;
    height: 25px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}



.preff-item3 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preff-item3-btn {
    width: 60px;
    display: flex;
    justify-content: center;
    padding: 8px;
    background-color: var(--mainColor);
    border-radius: 5px;
    cursor: pointer;
}

.preff-item3-btn h2 {
    color: #fff;
    font-size: 12px;
}

.preff-switches {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.preff-switches-item1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.switch-containers {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* margin-top: 10px !important; */
}

.switch-containers h3{
    font-size:  15px;
    color: var(--solidGrey);
    margin: 0;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
}