:root {
  --mainColor: #ee7f0d;
  --bgColor: rgba(238, 127, 13, 0.14);
  --bgColorLight: rgba(238, 127, 13, 0.02);
  --bgColorLight2: rgba(238, 127, 13, 0.14);
  --solidGrey: #66738f;
  --appGrey: #8e8e8e;
  --appGrey2: #e1e1e1;
  --appGrey3: #334d6e;
  --appRole: #f4b740;
  --appRoleText: #334d6e;
  --btnColor: #eed5ba;
  --appSecondary: #a0a3bd;
  --appLightPink: #fe9f99;
  --appText: #4e4b66;
  --appText2: #6e7191;
  --appInput: #eff0f7;
  --appGreen: #289d65;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

html {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */


input[type="date"]::-webkit-input-placeholder{
  color: white;
}

.styleTable {
  border-radius: 5px;
}

.styleTable thead tr th {
  background-color: rgba(238, 127, 13, 0.14) !important;
  color: #334d6e !important;
  font-weight: 600;
}

.styleTable tbody tr .MuiTableCell-root {
  color: black !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

button:focus {
  border: none;
  outline: none;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

.react-responsive-modal-modal {
  border-radius: 5px;
}

.AppError {
  color: rgba(247, 36, 36, 0.842);
  font-size: 12px;
  display: block;
  font-style: italic;
}

.sk-dot1,
.sk-dot2 {
  background-color: #ee7f0d !important;
}

.sk-dot2,
.sk-dot2 {
  background-color: #ee7f0d !important;
}

select,
input,
button {
  outline: none !important;
  border: none;
}

select,
input {
  padding-left: 10px;
}

.css-g1d714-ValueContainer {
  height: 53px;
  display: flex;
  align-items: center;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #ee7f0d;
    --active-inner: white;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #ee7f0d;
    --border-hover: #ee7f0d;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* height: 21px; */
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    cursor: not-allowed;
  }
  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"]:not(.switch),
  input[type="radio"]:not(.switch) {
    width: 21px;
  }
  input[type="checkbox"]:not(.switch):after,
  input[type="radio"]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"]:not(.switch):checked,
  input[type="radio"]:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
  input[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }
  input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
  input[type="radio"] {
    border-radius: 50%;
  }
  input[type="radio"]:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type="radio"]:checked {
    --s: 0.5;
  }
}

input[type="radio"] {
  width: 15px !important;
  height: 15px !important;
}

input[type="checkbox"] {
  width: 21px !important;
  height: 21px !important;
}

input[type="radio"]:after {
  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  background: var(--active-inner);
  opacity: 0;
  transform: scale(var(--s, 0.7));
}

@font-face {
  font-family: generalFont;
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: boldFont;
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: semiBold;
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: generalFontM;
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: boldFontM;
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: semiBoldM;
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}

* {
  font-family: generalFont;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* margin: 0; */
}

/* react tag */

.react-tagsinput {
  border: 1px solid #f2f2f2 !important;
  padding-left: 9px !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.react-tagsinput-tag {
  background: var(--mainColor) !important;
  border-radius: 10px !important;
  padding: 10px !important;
  width: 100px;
  color: #fff !important;
  height: 35px !important;
  cursor: pointer;
  text-align: center !important;
}

.react-tagsinput-remove {
  color: #fff !important;
}

.react-tagsinput-remove:hover {
  color: red !important;
}

.dragElementHeader {
  display: flex;
  /* justify-content: space-between; */
}

.dragElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px !important;
}

.editor iframe {
  width: 100% !important;
  height: 300px !important;
}

.wrapperClassName {
  border: 1px solid rgba(128, 128, 128, 0.452) !important;
  border-radius: 5px;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 2px;
}

.wrapperClassName input {
  padding-left: 10px !important;
}

.DraftEditor-editorContainer {
  padding: 10px;
}

.public-DraftStyleDefault-ltr {
  font-size: 12px;
}

.rdw-option-wrapper {
  /* padding: 8px !important; */
  background-color: #ee7e0d50 !important;
  border-radius: 4px !important;
  padding-bottom: 13px !important;
  padding-top: 13px !important;
}

.rdw-option-wrapper img {
  margin: 3px;
}

.rdw-option-wrapper:hover {
  transform: translateY(-2px);
}

.rdw-embedded-modal {
  height: auto !important;
}

.rdw-embedded-modal-header-option {
  white-space: nowrap;
  font-size: 13px;
  /* color: #ee7f0d; */
}

.rdw-embedded-modal-header-label {
  border-bottom: 1px solid #ee7f0d !important;
}

.rdw-embedded-modal-btn:nth-of-type(1) {
  background-color: #ee7f0d !important;
  color: white;
  border-radius: 4px;
}

.rdw-image-modal-btn:nth-of-type(1) {
  background-color: #ee7f0d !important;
  color: white;
  border-radius: 4px;
}

.DraftEditor-editorContainer {
  height: 400px !important;
}
