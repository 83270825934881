.navbar-wrap {
  background-color: white;
  height: 80px;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 40;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.103));
}

.navbar-position {
  position: relative;
}
.navbar-pad {
  padding: 10px 20px 10px 20px;
}

.sidebar-wrap {
  height: calc(100vh - 80px);
  bottom: 0;
  background: var(--mainColor);
  /* background: rgba(238, 127, 13, 0.14); */
  width: 240px;
  position: fixed;
  z-index: 55;
}

.backgroundImage-wrap {
  background-image: url(../../assets/body.jpeg);
  width: 100vw;
  height: 100vh;
  background-size: cover;
  /* background-color: #f8f8f8; */
  background-position: center;
  position: fixed;
  z-index: 0;
  top: 0;
  background-color: #e8e9eb;
}

.app-container {
  position: relative;
  left: 250px;
  width: calc(100vw - 275px);
  top: 100px;
  padding: 0 15px;
  height: 90vh;
}

/* .routeName {} */

.sidebar-list {
  list-style-type: none;
  padding-left: 10px;
  /* height: 60%; */
  overflow: scroll;
}

.sidebar-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.active-dot-sidebar {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(238, 127, 13);
}

.sidebar-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  letter-spacing: 2px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  color: #fff;
  /* border: 1px solid red; */
}

.sidebar-chf5 {
  display: flex;
  align-items: center;
}

.logout-btn {
  border: none !important;
  padding: 8px;
  background: var(--mainColor);
  border-radius: 6px;
}

.logout-btn h2 {
  font-size: 14px;
  margin: 0;
  color: "#fff" !important;
}

.sidebar-i {
  margin-right: 5px;
  width: 15px;
  height: 10px;
}

.active-sidebar-route {
  background-color: white;
  border-right: 1px solid rgb(238, 127, 13);
  color: var(--mainColor) !important;
}

.sidbar-link-total {
  font-weight: 600;
}

.sidbar-link-total span {
  font-weight: 400;
  font-size: 13px;
}

.sidebarLogo {
  margin-top: -18px;
  padding-top: 0;
  margin-bottom: 20px;
}

.sidebarLogo img {
  width: 110px;
  margin-top: 20px;
}

.navbar-pad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.app-logo-wrap-layout-page {
  margin-right: auto;
}

.app-logo-wrap-layout-page img {
  width: 119px;
  height: 40px;
}

.avatarIcaon-nav {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nav-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 16vw;
  /* border: 1px solid red; */
}

.nav-actions img {
  width: 30px;
  height: 27px;
  margin-right: 35px;
  cursor: pointer;
}

.nav-cog {
  cursor: pointer !important;
}

.company-name {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--mainColor);
  /* background: rgba(238, 127, 13, 0.5); */
  /* width: 30%; */
  margin-left: 115px;
  padding: 12px 25px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
}

.company-name h2 {
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  margin: 0;
}

.nav-footer-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  border: 1px solid #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.sidebar--current {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sidebar--current-item1 {
  flex: 1;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 5px 6px 8px rgba(0, 0, 0, 0.4);
  /* display: ; */
}
.sidebar--current-item1 h3 {
  margin: 0;
}
.sidebar--current-item2 {
  /* border: 1px solid red; */
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
}

.sidebar--current-item2 h3 {
  font-size: 15%;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  font-family: "Poppins" sans-serif !important;
}

.sidebar--current-item3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* border: 1px solid red; */
}

.pub--dropdown {
  width: 100%;
  border-radius: 5px;
  background: var(--mainColor);
  border-bottom: 1px solid #fff !important;
  max-height: 60vh;
  /* overflow-y: scroll; */
  position: absolute;
}

.pub--dropdown2 {
  border-radius: 5px;
  background: rgba(238, 127, 13, 0.14);
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  height: 60px;
}

.pub--dropdown2 h3 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 0;
}

.pub--dropdown li {
  color: #fff;
  /* font-weight: bold; */
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.pub--dropdown li:hover {
  background: var(--appRoleText);
}

.add {
  background: var(--appRoleText);
}

.responsiveSideBar {
  height: 100vh;
  background-color: transparent;
  width: 200px;
  position: fixed;
  z-index: 55;
}

@media (max-width: 906px) {
  .sidebar-wrap {
    background-color: white;
    filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
  }

  .navbar-wrap {
    background-color: white;
    filter: drop-shadow(10px 1px 5px rgba(128, 128, 128, 0.233));
  }

  .app-container {
    position: relative;
    left: 0;
    width: 100vw;
    top: 24px;
    overflow-x: hidden;
  }

  .navbar-wrap {
    height: 75px;
    position: relative;
    width: 100vw;
    top: 0;
    right: 0;
    padding-left: 30px;
    z-index: 40;
    overflow-x: hidden;
    display: flex;
    align-items: center;
  }

  .active-sidebar-route {
    background-color: #0a1531;
    /* color: white; */
  }

  .active-sidebar-route div span {
    color: var(--mainColor) !important;
  }

  .navbar-pad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .backgroundImage-wrap {
    display: none;
  }

  #App .navRoutes {
    margin-right: auto;
    padding-left: 100px;
  }

  #App .navRoutesActions {
    padding-right: 20px;
  }

  #AppWrap .generalPadding {
    padding-left: 20px;
    padding-top: 40px;
  }

  .ham-toggle {
    display: block;
  }

  #App {
    justify-content: left;
  }

  #App .navRoutes {
    display: none;
  }

  #App .navRoutesActions {
    margin-left: auto;
  }

  #AppWrap {
    width: 100vw;
    background: #e5e5e5;
    height: calc(100vh - 75px);
  }

  #AppWrap .generalPadding {
    padding-left: 20px;
    padding-top: 40px;
  }

  .responsiveSideBar {
    width: 60vw;
    z-index: 8;
  }

  .responsiveSideBarOpen {
    animation: showbar 500ms ease-in forwards;
  }

  .AppCloseSidebar {
    animation: hidebar 500ms ease-out forwards;
  }

  @keyframes showbar {
    0% {
      transform: translateX(-500px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes hidebar {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-500px);
    }
  }
  .initSidebarState {
    transform: translateX(-500px);
  }
  #ApiHeader {
    width: 95%;
  }

  .navRoutesLogo {
    margin-left: 0 !important;
  }
}

.ApisideDropdown {
  list-style-type: none;
  padding: 0;
  margin-left: 15px;
  margin-top: 10px;
  margin-top: 30px;
  margin-left: 0;
}

.ApisideDropdown .sidebarHeader-list {
  font-family: Gotham;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  border: 0.277929px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  border-left: none;
  border-right: none;
}

.subSidbar-Details {
  margin-top: 10px;
  list-style-type: none;
}

.subSidbar-Details .subSidbar-Details-child {
  font-family: Gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  border: none;
  color: white;
  cursor: pointer;
}

.ham-toggle {
  display: none;
}

.img--container {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.img--container img {
  width: 49px !important;
}

@media (max-width: 611px) {
}

@media (max-width: 325px) {
  #App .navRoutesActions button:last-child {
    width: 60px;
    margin-left: 10px;
  }

  #App .navRoutesActions button:first-child {
    width: 60px;
  }

  #App .navRoutesLogo img {
    width: 70px;
    margin-left: 13px;
  }

  #AppWrap .generalPadding {
    padding-top: 10px;
  }
}

.active-sidebar-route .sidebar-link .routeName {
  color: var(--mainColor) !important;
}

.sidebar-list li .routeName:hover {
  transform: translateY(-3px);
  transition: all 100ms ease;
  font-size: 14px;
}
/* .sidebar-list li .routeName:hover{

  color: red !important;
} */
