.changeOfNameHeroImg {
  background-image: url(../../assets/images/changeOfNameHero.png);
  width: 100% !important;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.changeOfNameHeroImg h1 {
  color: #eaf6fc;
  font-size: 35px;
  font-weight: 600;
}

.changeOfNameHeroImg p {
  color: #eff0f7f1;
  font-size: 15px;
  text-align: center;
  margin-top: 12px;
}

.fillCnCol {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.fillCnColCol1 {
  margin-right: 50px;
  width: 120px;
}

.fillCnColCol1 h1 {
  font-weight: 600;
  color: #334d6e;
  font-size: 15px;
}

.fillCnColCol2 .kwegqo {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 7px;
  width: 320px;
  height: 50px;
  padding: 10px;
  font-size: 14px;
  color: #6e7191;
}

.qpgdscbCnAME span {
  font-size: 10px;
  margin-left: 6px;
  color: #999999;
}

.fillCnColCol2 ul {
  width: 320px;
  height: 50px;
  padding: 10px;
  font-size: 14px;
  color: #6e7191;
  list-style-type: none;
  height: 190px;
}

.fillCnColCol2 ul li {
  margin-bottom: 5px;
  color: #6e7191;
}

.fillCnColCol2 ul li input {
  margin-right: 10px;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 2px;
}

.lineBreakCNsec {
  width: 100%;
  border: 0.8px dashed rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
  margin-top: 5px;
}

.changerConfessCN p {
  color: #6e7191;
  font-size: 16px;
}

.changerConfessCN p span {
  color: #ee7f0d;
  font-size: 17px;
}

.changofnameLin {
  width: 100%;
  border: 1px dashed #d9dbe9;
}

.changeOfNameNewNPreview {
  display: flex;
}

.changeOfNameNewNPreview,
.changeOfNameNewNPreviewOne {
  margin-right: 140px;
}

.changeOfNameNewNPreviewOne h2,
.changeOfNameNewNPreviewtwo h2 {
  font-size: 17px !important;
  font-weight: 600;
  margin: 0;
}

.changeOfNameNewNPreviewOne p,
.changeOfNameNewNPreviewtwo p {
  font-size: 16px;
  color: #4e4b66;
  margin: 0;
  margin-top: 10px;
}

.dcCns {
  display: flex;
}
.dcCns .changeOfNameDownloadImg {
  width: 120px;
  height: 150px;
  background-color: #9b9ba0;
  margin-right: 13px;
  box-shadow: 0px 6.71186px 16.7797px rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dcCns div img {
  width: 95%;
  height: 95%;
}
.changeOfNameDownload {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.changeOfNameDownload a {
  color: #334d6e;
  margin-top: 5px;
}
.continputWrap {
  margin-bottom: 13px;
}
.continputWrap input,
select {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  padding-left: 8px;
  outline: none;
  padding-right: 8px;
}

.inputnwChtab3 {
  display: flex;
}
.inputnwChtab3 .inputnwChtab3FakeFileI {
  width: 90%;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px 0 0px 4px;
  cursor: pointer;
}

.inputnwChtab3label {
  position: absolute;
  left: 12px;
  font-size: 12px;
  top: 15px;
  cursor: pointer;
}
.inputnwChtab3 .mwhgeqinputTab3 {
  width: 90%;
  height: 50px;
  position: absolute;
  top: 0;
  opacity: 0;
  cursor: pointer;
  cursor: pointer;
}

.inputnwChtab3Button {
  border-radius: 0px 7px 7px 0px;
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
  background: #ee7f0d;
  color: white;
  font-size: 14px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionBtnCName {
  margin: 30px;
  width: 130px;
  height: 45px;
  border-radius: 4px;
  color: white;
  background: #ee7f0d;
  border: none;
  margin-bottom: 15px;
}

.proveOfReasonDocsCON {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.proveOfReasonDocsCON .proveOfReasonCol1 {
  width: 48%;
}

.proveOfReasonDocsCON .proveOfReasonCol1 img {
  width: 100%;
  height: 300px;
}

.proveOfReasonDocsCON .proveOfReasonCol1 p {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 600;
}

.downloadProveOfReasonDocs {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.downloadProveOfReasonDocs a {
  background: #ee7f0d;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.closeCONdocModald {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 20px;
}

.closeCONdocModald button {
  padding: 10px;
  border: none;
  background-color: transparent;
  border: 1px solid #ee7f0d;
  color: #ee7f0d;
  font-size: 13px;
  width: 140px;
}

.progress-bar {
  background: green !important ;
}
