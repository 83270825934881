h1 {
  font-size: 1.2vw;
  font-family: "Montserrat", sans-serif;
}
h3 {
  font-size: 15px;
  /* line-height: 3vh; */
  font-family: "Montserrat", sans-serif;
}

.react-responsive-modal-container {
  background: #dedede80 !important;
}

.onboard-title {
  font-size: 18px;
  font-family: semiBold;
}

.onboard-- h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: var(--appSecondary);
}

.btn-onbording2 {
  padding: 12px;
  border: none !important;
  background: var(--bgColor);
  border-radius: 10px;
}

.btn-onbording2 h2 {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

Form Input {
  background: #eff0f6;
  border-radius: 5px;
  width: 20vw;
  font-size: 12px !important;
  border: none;
  height: 40px !important;
}

p {
  margin: 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.modal-grid {
  display: flex;
  justify-content: space-between;
}

.btn-modal {
  background: var(--mainColor) !important;
}

.btn-modal h2 {
  color: red;
}

.modal-bos {
  width: 40vw;
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* justify-content: center; */
  align-items: center;
}

.onbording {
  height: 100vh;
  display: flex;
}

.onboarding--side {
  background: var(--mainColor);
  width: 35%;
  display: flex;
  justify-content: center;
  padding-top: 6%;
}

.onboarding--main {
  flex: 1;
  padding: 6% 0 0 5%;
  height: 100%;
  overflow: hidden;
}

.onboarding--main h1 {
  margin: 0;
  font-size: 35px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}
.onboarding-welcome {
  margin: 0;
  color: var(--mainColor);
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}

.onboarding-info {
  margin-top: 20px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}

.onboarding-info h3 {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  color: var(--appText);
  letter-spacing: 1px;
  line-height: 20px;
}

.input-wrapper {
  margin-top: 40px;
  display: flex;
  /* justify-content: space-between; */
}
.input-wrapper-item1 {
  flex: 1;
}
.input-wrapper-item2 {
  flex: 1;
}

.input-wrapper-title {
  font-size: 15px !important;
}
.input-wrapper input {
  width: 90%;
  height: 60px;
  padding: 10px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px;
  color: var(--appText);
  letter-spacing: 1px;
  line-height: 20px;
  border-radius: 8px;
  border: none;
  background: var(--appInput);
}

.input-wrapper select {
  width: 90%;
  height: 60px;
  padding: 13px 10px;
  border-radius: 8px;
  border: none;
  background: var(--appInput);
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px;
  color: var(--appText2);
  letter-spacing: 1px;
  line-height: 20px;
}

.input-wrapper select:focus {
  outline-color: var(--bgColor);
}

.btn-wrapper {
  display: flex;
  justify-content: center;
}

.btn-wrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 12px;
  width: 20%;
  border-radius: 5px;
  background: var(--mainColor);
}
.btn-wrapper button > h3 {
  color: #fff;
  margin: 0;
}

.input-wrapper input:focus {
  outline-color: var(--bgColor);
}

.input-wrapper input::placeholder {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px;
  color: var(--appText2);
  letter-spacing: 1px;
  line-height: 20px;
}

.input-wrapper--container h3 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  color: var(--appText2);
  letter-spacing: 1px;
  line-height: 20px;
}

.logo-container {
  background: #fff;
  padding: 5px;
  width: 150px;
  margin-bottom: 30px;
}

.side-wrapper {
  width: 60%;
  height: 100%;
  overflow: hidden !important;
}

.side-content {
  height: 60vh;
}

.side-content h1 {
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

.side-content h3 {
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
}

@media (max-width: 600px) {
  .onboarding--side {
    display: none;
  }

  .onboarding--main {
    padding: 20px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .input-wrapper input {
    width: 100%;
  }

  .input-wrapper select {
    width: 100%;
  }

  .btn-wrapper button {
    width: 80%;
  }
}
