.table-avatar {
  width: 40px;
  height: 40px;
}

.pending-action-table-btn {
  width: fit-content;
  padding: 5px 10px 5px 10px;
  height: 30px;
  background: #ee7f0d;
  border-radius: 5px;
  color: white;
  border: none;
  outline: none;
}

.review-edit {
  margin: 0;
  padding: 0;
  color: #ee7f0d;
  font-size: 12px;
  cursor: pointer;
  margin-right: 6px;
}

.rating-star img {
  margin-bottom: 10px;
  width: 15px;
  height: 15px;
  margin-right: 3px;
}

.edition-status-tab h2 {
  font-size: 15px;
}

td div p {
  margin: 0;
  padding: 0;
}

.styleTable {
  padding: 40px;
}

.table-wrap.border .styleTable {
  border: none !important;
}

.styleTable tbody tr .MuiTableCell-root {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: var(--solidGrey) !important;
}

[data-rbd-drag-handle-context-id="1"] {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 20px !important;
  /* letter-spacing: 2px !important; */
}

.edition-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.MuiTypography-h6 {
  font-size: 16px !important;
  color: #000000 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
}

.optionsTab {
  margin-top: 5px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.optionsTab h2 {
  margin: 0;
  color: var(--mainColor);
  font-size: 12px;
  cursor: pointer;
}

/* [data-rbd-drag-handle-context-id="1"] */
