/* .dragContainer {
    width: 90%;
    margin: 0 auto;
} */

.dragHandler {
  /* padding: 1px 5px; */
  color: orange;
  /* background: #ffffff; */
  /* border-radius: 3px; */
  cursor: -webkit-grab;
  cursor: grab;
  /* border: 1px solid; */
}

.dragElement {
  position: relative;
  /* display: flex; */
  /* justify-content: space-between;
    align-items: center; */
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}

/* table .dragContainer tr td {
    background-color: red;
} */

.sortableHelper {
  z-index: 10000 !important;
}

.article-list__table-view .table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  width: 150px !important;
  overflow: hidden;
}
