@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .publicationCheckbox input[type="checkbox"],
    .publicationCheckbox input[type="radio"] {
        --active: #ee7f0d;
        --active-inner: white;
        --focus: 2px rgba(39, 94, 254, 0.3);
        --border: #ee7f0d;
        --border-hover: #ee7f0d;
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }
    .publicationCheckbox input[type="checkbox"]:after,
    .publicationCheckbox input[type="radio"]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }
    .publicationCheckbox input[type="checkbox"]:checked,
    .publicationCheckbox input[type="radio"]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    .publicationCheckbox input[type="checkbox"]:disabled,
    .publicationCheckbox input[type="radio"]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
    }
    .publicationCheckbox input[type="checkbox"]:disabled:checked,
    .publicationCheckbox input[type="radio"]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }
    .publicationCheckbox input[type="checkbox"]:disabled+label,
    .publicationCheckbox input[type="radio"]:disabled+label {
        cursor: not-allowed;
    }
    .publicationCheckbox input[type="checkbox"]:hover:not(:checked):not(:disabled),
    .publicationCheckbox input[type="radio"]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }
    .publicationCheckbox input[type="checkbox"]:focus,
    .publicationCheckbox input[type="radio"]:focus {
        box-shadow: 0 0 0 var(--focus);
    }
    .publicationCheckbox input[type="checkbox"]:not(.switch),
    .publicationCheckbox input[type="radio"]:not(.switch) {
        width: 21px;
    }
    .publicationCheckbox input[type="checkbox"]:not(.switch):after,
    .publicationCheckbox input[type="radio"]:not(.switch):after {
        opacity: var(--o, 0);
    }
    .publicationCheckbox input[type="checkbox"]:not(.switch):checked,
    .publicationCheckbox input[type="radio"]:not(.switch):checked {
        --o: 1;
    }
    .publicationCheckbox input[type="checkbox"]+label,
    .publicationCheckbox input[type="radio"]+label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
    }
    .publicationCheckbox input[type="checkbox"]:not(.switch) {
        border-radius: 7px;
    }
    .publicationCheckbox input[type="checkbox"]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }
    .publicationCheckbox input[type="checkbox"]:not(.switch):checked {
        --r: 43deg;
    }
    .publicationCheckbox input[type="checkbox"].switch {
        width: 38px;
        border-radius: 11px;
    }
    .publicationCheckbox input[type="checkbox"].switch:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
    }
    .publicationCheckbox input[type="checkbox"].switch:checked {
        --ab: var(--active-inner);
        --x: 17px;
    }
    .publicationCheckbox input[type="checkbox"].switch:disabled:not(:checked):after {
        opacity: 0.6;
    }
    .publicationCheckbox input[type="radio"] {
        border-radius: 50%;
    }
    .publicationCheckbox input[type="radio"]:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, 0.7));
    }
    .publicationCheckbox input[type="radio"]:checked {
        --s: 0.5;
    }
}

.upload-pdf-container-wrap {
    margin-top: 20px;
}

.pdf-input-box-upload {
    width: 500px;
    /* height: 49px; */
    border: 1px solid #ee7e0d81;
    /* position: relative; */
    border-radius: 5px;
}

.pdf-input-box-upload input {
    position: absolute;
    height: 100%;
    width: 80%;
    left: 0;
    opacity: 1;
}

.pdf-input-box-upload button {
    position: absolute;
    right: 0;
    height: 100%;
    border: none;
    padding: 5px 10px 5px 10px;
    border-radius: 1px 5px 5px 0px;
    background-color: #ee7f0d;
    color: white;
}

.upload-btn {
    border: none;
    /* padding: 5px 10px 5px 10px; */
    border-radius: 1px 5px 5px 0px;
    background-color: #ee7f0d !important;
    color: white;
}

.pdf-container-wrap-display {
    margin-top: 30px;
    border: 1px solid rgba(128, 128, 128, 0.438);
    width: 100%;
    height: 900px;
    border-radius: 5px;
    padding: 20px;
}

.react-responsive-modal-container {
    background-color: rgba(238, 127, 13, 0.14);
}

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 10px;
}

.lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #ee7f0d;
    border-color: #ee7f0d transparent #ee7f0d transparent;
    -webkit-animation: lds-hourglass 1.2s infinite;
    animation: lds-hourglass 1.2s infinite;
}

@-webkit-keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}

.slide {
    background-color: inherit !important;
}

.slide-image-wrap-upload-pdf {
    background-color: #334d6e;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide-image-wrap-upload-pdf img {
    width: 50px !important;
    height: 50px !important;
}

.slide-wrap-upload-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    flex-direction: column;
    padding: 20px 10px 20px 10px;
}

.upload-pdf-slipe-caption {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
}

.waya-modal-body h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-top: 20px;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
}

.pdf-wrapData {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.webviewer {
    flex: 1;
    margin: 8px;
    box-shadow: 1px 1px 10px #999;
    height: 100% !important;
}

.edit-wrapper {
    display: flex;
    flex-direction: column;
    height: 90%;
}

.edit-wrapper-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    outline-color: #92a8d1;
    border: 10px solid var(--bgColor);
    height: 60vh;
    overflow: hidden;
    padding: 50px 0px;
}

.edit-wrapper-container Input {
    border: 1px solid var(--mainColor);
    font-size: 12px;
    width: 70%;
    padding: 20px 10px;
}

.edit-wrapper-container select {
    border: 1px solid var(--mainColor);
    font-size: 12px;
    width: 70%;
    padding: 11px 10px;
    border-radius: 5px;
}