
.analytics {
   background-color: var(--bgColorLight);
   padding-top: 1vh;
   height: 85vh;
   overflow-y: scroll;
}

.analytics--card {
    padding: 0 1em;
    margin-top: 6vh;
}
.analytics--card_item {
    /* border: 1px solid red; */

}

.analytics--card_item--child {
    border-radius: 10px;
    padding: 3vh 1vw;
    background-color: #FFFFFF;
}

.analytics--card_item--child h1, h2 {
    font-weight: 600;
}
.analytics--card_item--child h1 {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400 !important;
}
.analytics--card_item--child  h2 {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: var(--appGrey);
}

.dashboard-layout{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
   

}

.dashboard-overview {
    width: 70% !important;
    height: 100% !important;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
}

.barChart-overview {
    width: 70% !important;
    height: 60% !important;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
}

.barChart-overview .chart-canvas{
    width: 100% !important;
    height: 100% !important;
}

.barChart-overview .chart-canvas canvas{
    width: 100% !important;
    height: 100% !important;
}

.dashboard-overview .chart-canvas{
    width: 100% !important;
    height: 100% !important;
}

.dashboard-overview .chart-canvas canvas{
    width: 100% !important;
    height: 300px !important;
}



