.App {
    background: black;
    padding: 67px;
    text-align: center;
  }

  .canvas-home {
    display: flex;
    justify-items: center;
  }
  
  #the-canvas {
    width: 100%;
    margin: 0 auto;
  }
  
  canvas {
    width: 100%;
  }

  
  .react-responsive-modal-container {
    background: #14274E32 !important;
    backdrop-filter: blur(5px);
  }



  .modal-container {
    height: 90vh !important;
    overflow: auto;
  }
  
  .control-section {
    display: flex;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0px;
    border-radius: 5px;
    right: 0;
    width: 100%;
    background: var(--appGrey);
  }
  
  .control-section-col1 {
    flex: 1;
  }

  .control-section-col2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .control-section-col3 {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 4px;
  }

  .control-section-col3 button {
    border: none;
    padding: 4px;
    border-radius: 5px;
    font-size: 12px;
    background: var(--mainColor);
    color: #fff;
    width: 70px;
  }
  .control-section i {
    font-size: 30px;
    color: #fff;
  }
