.breadcrum-nav {
    /* flex: 1; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

}

.breadcrum-nav h2 {
    font-size: 14px !important;
    color: var(--solidGrey);
    letter-spacing: 1px;
    font-family: semiBold;
}

.breadcrum-nav div {
    /* flex: 1; */
    /* padding: 5px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    padding: 5px;
    /* width: 8vw; */
    
}

.breadcrum-nav div:hover{
    cursor: pointer;
    transform: scale(1.1);
    border-radius: 5px;
    border-bottom: 1px solid var(--mainColor)
}
.active{
    border-radius: 5px;
    border-bottom: 1px solid var(--mainColor)
}

